<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>Templates</h1>
                    <router-link class="btn" to="/dispute/employee/group/show" active-class="active" style="float: right;">Back</router-link>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;">
                <div class="col-12">
                    <table class="table">
                        <thead class="thead-dark">
                        <tr>
                            <th>name</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="item in list">
                            <tr>
                                <td>{{item.fileName}}</td>
                                <td>
                                    <button @click="getRaw(item)" class="btn" style="margin-right: 15px;">see raw</button>
                                    <button @click="getPreview(item)" class="btn">preview</button>
                                </td>
                            </tr>
                            <tr class="no-hover" v-show="item.data && item.show === 'prev'">
                                <td colspan="2">
                                    <iframe :id="`iframeTemplate-${item.id}`" frameborder="0"></iframe>
                                    <!--<div v-html="item.data"></div>-->
                                </td>
                            </tr>
                            <tr class="no-hover" v-if="item.show === 'raw'">
                                <td colspan="2">
                                    <div class="container" style="padding: 0;">
                                        <!--<div v-highlight>-->
                                            <!--<pre>-->
                                                <!--<code class="XML" style="height: 400px;">-->
                                                  <!--{{item.data}}-->
                                                <!--</code>-->
                                            <!--</pre>-->
                                        <!--</div>-->
                                        <div style="text-align: center;">
                                            <textarea style="width: 100%;height: 400px;" v-model="item.data"></textarea>
                                            <a href="" @click.prevent="saveTemplate(item)" class="btn">save</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	export default {
		name: "TemplateList",
        data() {
			return {
                list: []
            }
        },
        mounted() {
			this.getTemplates()
        },
        methods: {
	        getTemplates() {
	        	disputeAxios
                    .get('/api/template-editor/list')
                    .then(({data}) => {
	                    data.templates.forEach((item, index) => {
		                    this.list.push({
                                id: index,
			                    fileName: item,
                                data: '',
                                show: ''
                            })
                        })
                    })
                    .catch(err => console.error(err))
            },
	        getRaw(item) {
		        if (item.show !== 'raw') {
			        disputeAxios
				        .get(`/api/template-editor/content/raw/${item.fileName}`)
				        .then(({data}) => {
					        item.data = data.code === 2006 ? data.content : ''
					        item.show = 'raw'
                            this.$forceUpdate()
                        })
				        .catch(err => console.error(err))
                } else {
			        item.data = ''
			        item.show = ''
                }
            },
	        getPreview(item) {
		        if (item.show !== 'prev') {
			        disputeAxios
				        .get(`/api/template-editor/content/ready/${item.fileName}`)
				        .then(({data}) => {
					        item.data = !!data ? data : ''
					        item.show = 'prev'
					        Helper.fillEmailIframe(`iframeTemplate-${item.id}`, item.data)
					        // this.$forceUpdate()
                        })
				        .catch(err => console.error(err))
		        } else {
			        item.data = ''
			        item.show = ''
		        }
            },
	        saveTemplate({fileName, data}) {
		        disputeAxios
			        .patch(`/api/template-editor/content/save/${fileName}`, { content: data })
			        .then(({data}) => data.code === 2000 ? EventBus.$emit('showAlertModal', 'Saved') : null)
			        .catch(err => console.error(err))
            }
		}
	}
</script>

<style lang="scss">
.no-hover{
    &:hover {
        background-color: initial !important;
    }
    >td {
        padding: 0 !important;
    }
     tbody tr:hover {
        background: initial !important;
    }
}
    iframe {
        width: 100%;
        height: 600px;
    }
</style>